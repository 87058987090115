export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [0];

export const dictionary = {
		"/[lang]/(public)": [3,[2]],
		"/[lang]/(public)/about": [4,[2]],
		"/[lang]/(public)/business": [5,[2]],
		"/[lang]/(public)/cookies": [6,[2]],
		"/[lang]/(public)/faq": [7,[2]],
		"/[lang]/(public)/how": [8,[2]],
		"/[lang]/(public)/intro": [9,[2]],
		"/[lang]/(public)/join": [10,[2]],
		"/[lang]/(public)/labs": [11,[2]],
		"/[lang]/(public)/manifesto": [12,[2]],
		"/[lang]/(public)/privacy": [13,[2]],
		"/[lang]/(public)/questionnaire_soon": [15,[2]],
		"/[lang]/(public)/questionnaire": [14,[2]],
		"/[lang]/(public)/teasing": [16,[2]],
		"/[lang]/(public)/terms-website": [18,[2]],
		"/[lang]/(public)/terms": [17,[2]],
		"/[lang]/(public)/tests-and-biomarkers": [19,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';